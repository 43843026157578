<template>
  <div>
    <div>
      <legends class="mb-2" :type="type" />
      <v-divider class="my-2" />
    </div>
    <div
      class="px-5 mb-2 d-flex flex-column justify-center text-center flex-sm-row justify-sm-space-between align-sm-center"
    >
      <v-btn class="order-0" outlined color="grey darken-2" @click="setToday">
        Hoje
      </v-btn>
      <div class="order-2 order-sm-1 d-flex align-center justify-center">
        <v-btn fab text color="grey darken-2" @click="prevMonth">
          <v-icon>chevron_left</v-icon>
        </v-btn>
        <span>{{ focusMonth }}</span>
        <v-btn fab text color="grey darken-2" @click="nextMonth">
          <v-icon>chevron_right</v-icon>
        </v-btn>
      </div>
      <v-menu bottom right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="order-1 order-sm-2 mt-2 mt-sm-0"
            outlined
            color="grey darken-2"
            v-bind="attrs"
            v-on="on"
          >
            <span>{{ typeToLabel[type] }}</span>
            <v-icon right>expand_more</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="typeItem in typeList">
            <v-list-item
              :key="typeItem.key"
              @click="changeType(typeItem.value)"
            >
              <v-list-item-title>{{ typeItem.label }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </div>
    <v-calendar
      ref="calendar"
      v-model="focus"
      :now="focus"
      :events="appointments"
      color="primary"
      :type="type"
      :start="yesterday"
      :end="next3Months"
      event-start="startAt"
      event-end="endAt"
      :interval-width="$vuetify.breakpoint.xsOnly ? 30 : 50"
      :event-height="0"
      :event-margin-bottom="0"
      @click:event="changeEvent"
      @click:date="viewDay"
      @click:time="createEvent"
    >
      <template #day="{ date }">
        <div class="d-flex justify-center align-center">
          <v-icon
            v-show="hasAppointmentsDate(date)"
            color="primary"
            class="mt-2"
          >
            event_available
          </v-icon>
        </div>
      </template>
    </v-calendar>
  </div>
</template>

<script>
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import utcPlugin from "dayjs/plugin/utc";
import timeZonePlugin from "dayjs/plugin/timezone";
dayjs.locale("pt-br");
dayjs.extend(utcPlugin);
dayjs.extend(timeZonePlugin);

export default {
  name: "Calendar",
  components: {
    Legends: () =>
      import(
        "@/domains/appointments/therapist/presentation/components/Legends"
      ),
  },
  data: () => ({
    focus: dayjs().format("YYYY-MM-DD"),
    yesterday: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
    next3Months: dayjs().add(3, "month").format("YYYY-MM-DD"),
    type: "month",
    typeToLabel: {
      month: "Mensal",
      // week: "Semanal",
      day: "Diário",
      "4day": "4 dias",
    },
  }),
  computed: {
    appointments() {
      return this.$store.state.appointments.therapist.appointments;
    },
    typeList() {
      return Object.keys(this.typeToLabel).map((key) => ({
        value: key,
        label: this.typeToLabel[key],
      }));
    },
    focusMonth() {
      return dayjs(this.focus, "YYYY-MM-DD").format("MMMM");
    },
  },
  created() {
    this.$store.dispatch("appointments/therapist/getAppointmentsFromApi");
    if (this.$route.query?.selectedDate) {
      this.focus = this.$route.query.selectedDate;
      this.type = "day";
    }
  },
  methods: {
    viewDay() {
      this.type = "day";
    },
    setToday() {
      this.focus = dayjs().format("YYYY-MM-DD");
    },
    prevMonth() {
      this.focus = dayjs(this.focus).subtract(1, "month").format("YYYY-MM-DD");
    },
    nextMonth() {
      this.focus = dayjs(this.focus).add(1, "month").format("YYYY-MM-DD");
    },
    changeType(type) {
      this.type = type;
    },
    createEvent({ time, date }) {
      const STATUS_NO_PATIENT = 0;
      if (!this.validateToCreateEvent({ time, date })) return;
      this.$store.dispatch("appointments/therapist/openTimeDialogToCreate", {
        startAt: dayjs(time, "HH:mm").minute(0).format("HH:mm"),
        endAt: dayjs(time, "HH:mm").minute(0).add(1, "hour").format("HH:mm"),
        date,
        status: STATUS_NO_PATIENT,
      });
    },
    validateToCreateEvent({ time, date }) {
      const dayjsDate = dayjs(`${date} ${time}`).minute(0);
      if (dayjsDate.isBefore(dayjs())) {
        this.$toasted.global.error({
          message:
            "Data selecionada é menor que data atual. Selecione data futura.",
        });
        return false;
      }
      return true;
    },
    hasAppointmentsDate(date) {
      return this.appointments.some(
        (event) =>
          dayjs(event.startAt, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD") === date
      );
    },
    changeEvent({ nativeEvent, event }) {
      nativeEvent.stopPropagation();
      const [date, startAt] = event.startAt.split(" ");
      const [, endAt] = event.endAt.split(" ");
      this.$store.dispatch("appointments/therapist/openTimeDialogToEdit", {
        id: event.id,
        startAt,
        endAt,
        date,
        isPublic: event.isPublic,
        patientName: event.patientName,
        cancellationReason: event.cancellationReason,
        cancellationSource: event.cancellationSource,
        status: event.status,
        canDelete: event.canDelete,
        canEdit: event.canEdit,
        canCancel: event.canCancel,
      });
    },
  },
};
</script>

<style scoped></style>
